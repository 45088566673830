@media only screen and (max-width: 821px) {
  .download-wrapper {
    display: none;
  }
  .manifesto-wrapper {
    padding: 1rem;
    text-align: center;
  }
}

@media only screen and (max-width: 640px) {
  .download-wrapper {
    display: none;
  }
  .manifesto-wrapper {
    padding: 1rem;
    text-align: center;
  }
}
