@media only screen and (max-width: 821px) {
  .download {
    bottom: -2.5rem;
  }
}

@media only screen and (max-width: 640px) {
  .download {
    bottom: -2.5rem;
  }
}
